export default async ({ store, app: { $sentry } }) => {
  try {
    if (!store.state.auth.user) {
      const user = await store.dispatch("auth/getUser");
      store.commit("auth/setUser", user || null);
    }
  } catch (error) {
    store.commit("auth/setUser", null);
  }
};
