export const state = () => ({});

export const mutations = {
  pushToSate(state, { pn, value }) {
    this._vm.$set(state, pn, value);
  }
};

export const actions = {
  getStock({ dispatch }, pns) {
    dispatch("get1cStock", pns);
    // dispatch('getOcsStock', pns);
  },

  async get1cStock({ commit }, pns) {
    try {
      const { data } = await this.$axios.get(`/catalog/stock?pns=${pns}`);
      // console.log(data)
      Object.keys(data).forEach((key) => {
        commit("pushToSate", { pn: key, value: data[key] });
      });
    } catch (error) {
      throw error;
    }
  },

  async getOcsStock({ commit }, pns) {
    try {
      const { stock } = await this.$axios.$get(`/catalog/stock/ocs/?pns=${pns.join(",")}`);
      Object.keys(stock).forEach((key) => {
        commit("pushToSate", { pn: key, key: "ocs", value: stock[key] });
      });
    } catch (error) {
      throw error;
    }
  }
};
