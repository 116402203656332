class LabelPrinter {
  constructor() {
    this.printer = null;
  }

  async #connectToPrinter() {
    try {
      if (!this.printer) {
        const printWrapper = await import("zebra-browser-print-wrapper-https");
        const labelPrinter = new printWrapper.default();
        if (labelPrinter) {
          const defaultPrinter = await labelPrinter.getDefaultPrinter();
          labelPrinter.setPrinter(defaultPrinter);
          this.printer = labelPrinter;
        }
      }
    } catch (error) {
      throw error;
    }
  }

  async print(zCode) {
    try {
      await this.#connectToPrinter();

      const status = await this.printer.checkPrinterStatus();
      if (status.isReadyToPrint) {
        this.printer.print(zCode);
      } else {
        throw new Error(status.errors);
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export default ({ app }, inject) => {
  inject("labelPrinter", new LabelPrinter());
};
