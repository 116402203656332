const STORAGE_PRODUCTS_KEY = "buffer-products";

export const state = () => ({
  products: JSON.parse(window.localStorage.getItem(STORAGE_PRODUCTS_KEY) || "[]")
});

export const getters = {
  products: (state) => JSON.parse(JSON.stringify(state.products)),
  inProducts: (state) => (code) =>
    !!state.products.find((p) => (p.pn && p.pn === code) || (p.id && p.id === code))
};

export const mutations = {
  addProductToBuffer(state, product) {
    const already = !!state.products.find((item) => {
      return (item.pn && item.pb === product.pn) || (item.id && item.id === product.id);
    });
    if (already) return;

    state.products.push(product);
    window.localStorage.setItem(STORAGE_PRODUCTS_KEY, JSON.stringify(state.products));
  },
  removeProductFromBuffer(state, { key, value } = {}) {
    if (!key && !value) {
      state.products.splice(0, state.products.length);
    } else if (!key || !value) {
      return;
    } else {
      const idx = state.products.findIndex((product) => product[key] === value);
      if (idx >= 0) {
        state.products.splice(idx, 1);
      }
    }

    if (state.products.length) {
      window.localStorage.setItem(STORAGE_PRODUCTS_KEY, JSON.stringify(state.products));
    } else {
      window.localStorage.removeItem(STORAGE_PRODUCTS_KEY);
    }
  }
};
