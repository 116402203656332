export default ({ app, store }, inject) => {
  inject("toPrice", toPrice);
  inject("toDateTime", toDateTime);
  inject("toDateString", toDateString);
  inject("hasScope", (scopes) => store.getters["auth/hasScope"](scopes));
};

function toPrice(number, options = {}) {
  const { locale = "ru-RU", ...formatOptions } = options;
  return Intl.NumberFormat(locale, {
    style: "currency",
    currencyDisplay: "code",
    currency: "RUB",
    minimumFractionDigits: formatOptions.minFD ?? formatOptions.maxFD ?? 2,
    maximumFractionDigits: formatOptions.maxFD ?? formatOptions.minFD ?? 2,
    ...formatOptions
  }).format(number);
}

function toDateTime(date) {
  const dateString = new Date(date).toLocaleString("ru-RU", { timeZone: "America/New_York" });
  return dateString.replace(",", "");
}
function toDateString(date, ...params) {
  if (params.includes("ONLY_DATE")) {
    return new Date(date).toLocaleDateString("ru-RU", { timeZone: "Europe/Moscow" });
  } else {
    return new Date(date).toLocaleString("ru-RU", { timeZone: "Europe/Moscow" })?.replace(",", "");
  }
}
