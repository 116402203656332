import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f6be6094 = () => interopDefault(import('../pages/certificates/index.vue' /* webpackChunkName: "pages/certificates/index" */))
const _3a3a51d2 = () => interopDefault(import('../pages/copy-excel-table.vue' /* webpackChunkName: "pages/copy-excel-table" */))
const _45346ce2 = () => interopDefault(import('../pages/costs/index.vue' /* webpackChunkName: "pages/costs/index" */))
const _7dd42fb1 = () => interopDefault(import('../pages/customers/index.vue' /* webpackChunkName: "pages/customers/index" */))
const _4af81c33 = () => interopDefault(import('../pages/diadoc.vue' /* webpackChunkName: "pages/diadoc" */))
const _33fd957c = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _27367b81 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _a2cba9d8 = () => interopDefault(import('../pages/survey/index.vue' /* webpackChunkName: "pages/survey/index" */))
const _798e257a = () => interopDefault(import('../pages/transits/index.vue' /* webpackChunkName: "pages/transits/index" */))
const _f79db21e = () => interopDefault(import('../pages/version.vue' /* webpackChunkName: "pages/version" */))
const _15259226 = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _ff7fc2aa = () => interopDefault(import('../pages/admin/hello.vue' /* webpackChunkName: "pages/admin/hello" */))
const _2000f066 = () => interopDefault(import('../pages/admin/logs.vue' /* webpackChunkName: "pages/admin/logs" */))
const _cf1fd5a4 = () => interopDefault(import('../pages/admin/our-stock.vue' /* webpackChunkName: "pages/admin/our-stock" */))
const _81e11a96 = () => interopDefault(import('../pages/admin/sidekiq.vue' /* webpackChunkName: "pages/admin/sidekiq" */))
const _299010c7 = () => interopDefault(import('../pages/calc/cpt-ddp.vue' /* webpackChunkName: "pages/calc/cpt-ddp" */))
const _4cfc0810 = () => interopDefault(import('../pages/catalog/lists.vue' /* webpackChunkName: "pages/catalog/lists" */))
const _0e851d15 = () => interopDefault(import('../pages/catalog/orders/index.vue' /* webpackChunkName: "pages/catalog/orders/index" */))
const _bb0c6fd0 = () => interopDefault(import('../pages/catalog/search/index.vue' /* webpackChunkName: "pages/catalog/search/index" */))
const _7de618ef = () => interopDefault(import('../pages/catalog/selection.vue' /* webpackChunkName: "pages/catalog/selection" */))
const _56211c84 = () => interopDefault(import('../pages/certificates/new.vue' /* webpackChunkName: "pages/certificates/new" */))
const _672a58ec = () => interopDefault(import('../pages/enrollment/orders/index.vue' /* webpackChunkName: "pages/enrollment/orders/index" */))
const _0aeabb48 = () => interopDefault(import('../pages/enrollment/transactions/index.vue' /* webpackChunkName: "pages/enrollment/transactions/index" */))
const _0110acd2 = () => interopDefault(import('../pages/marketplace/offers.vue' /* webpackChunkName: "pages/marketplace/offers" */))
const _dfd394ba = () => interopDefault(import('../pages/marketplace/orders/index.vue' /* webpackChunkName: "pages/marketplace/orders/index" */))
const _b5e5d978 = () => interopDefault(import('../pages/marketplaces/dashboard.vue' /* webpackChunkName: "pages/marketplaces/dashboard" */))
const _0f1524ab = () => interopDefault(import('../pages/marketplaces/dispatches/index.vue' /* webpackChunkName: "pages/marketplaces/dispatches/index" */))
const _5b63551e = () => interopDefault(import('../pages/marketplaces/list.vue' /* webpackChunkName: "pages/marketplaces/list" */))
const _6b848eb0 = () => interopDefault(import('../pages/marketplaces/orders/index.vue' /* webpackChunkName: "pages/marketplaces/orders/index" */))
const _17e46a32 = () => interopDefault(import('../pages/marketplaces/reports/index.vue' /* webpackChunkName: "pages/marketplaces/reports/index" */))
const _251a52ad = () => interopDefault(import('../pages/pi/bank_accounts/index.vue' /* webpackChunkName: "pages/pi/bank_accounts/index" */))
const _106e0672 = () => interopDefault(import('../pages/pi/calc/index.vue' /* webpackChunkName: "pages/pi/calc/index" */))
const _1bfebc3f = () => interopDefault(import('../pages/pi/cost/index.vue' /* webpackChunkName: "pages/pi/cost/index" */))
const _588aa8ab = () => interopDefault(import('../pages/pi/declarations/index.vue' /* webpackChunkName: "pages/pi/declarations/index" */))
const _6fab4098 = () => interopDefault(import('../pages/pi/invoices/index.vue' /* webpackChunkName: "pages/pi/invoices/index" */))
const _44dd43e6 = () => interopDefault(import('../pages/pi/packing/index.vue' /* webpackChunkName: "pages/pi/packing/index" */))
const _5d9242bd = () => interopDefault(import('../pages/pi/shipments/index.vue' /* webpackChunkName: "pages/pi/shipments/index" */))
const _0fa74055 = () => interopDefault(import('../pages/public/upload-images.vue' /* webpackChunkName: "pages/public/upload-images" */))
const _2607566e = () => interopDefault(import('../pages/purchases/arrivals.vue' /* webpackChunkName: "pages/purchases/arrivals" */))
const _60e9c2ec = () => interopDefault(import('../pages/purchases/extra.vue' /* webpackChunkName: "pages/purchases/extra" */))
const _0fc224c1 = () => interopDefault(import('../pages/reports/delta.vue' /* webpackChunkName: "pages/reports/delta" */))
const _4bf16fe5 = () => interopDefault(import('../pages/reports/inventory.vue' /* webpackChunkName: "pages/reports/inventory" */))
const _55afd0a5 = () => interopDefault(import('../pages/reports/mac_sales.vue' /* webpackChunkName: "pages/reports/mac_sales" */))
const _e66b1074 = () => interopDefault(import('../pages/reports/report1.vue' /* webpackChunkName: "pages/reports/report1" */))
const _3faec462 = () => interopDefault(import('../pages/sales/current.vue' /* webpackChunkName: "pages/sales/current" */))
const _603a7bd9 = () => interopDefault(import('../pages/sales/customizable/index.vue' /* webpackChunkName: "pages/sales/customizable/index" */))
const _18385890 = () => interopDefault(import('../pages/sales/real.vue' /* webpackChunkName: "pages/sales/real" */))
const _38242c30 = () => interopDefault(import('../pages/sales/reported/index.vue' /* webpackChunkName: "pages/sales/reported/index" */))
const _1c99bb78 = () => interopDefault(import('../pages/stock/orders/index.vue' /* webpackChunkName: "pages/stock/orders/index" */))
const _26f29500 = () => interopDefault(import('../pages/stock/packages/index.vue' /* webpackChunkName: "pages/stock/packages/index" */))
const _77556d92 = () => interopDefault(import('../pages/stock/products/index.vue' /* webpackChunkName: "pages/stock/products/index" */))
const _9413bebc = () => interopDefault(import('../pages/stock/serials/index.vue' /* webpackChunkName: "pages/stock/serials/index" */))
const _c5bb3212 = () => interopDefault(import('../pages/stock/sn_addons.vue' /* webpackChunkName: "pages/stock/sn_addons" */))
const _3d47d152 = () => interopDefault(import('../pages/survey/check-winner.vue' /* webpackChunkName: "pages/survey/check-winner" */))
const _69939e4e = () => interopDefault(import('../pages/survey/participants.vue' /* webpackChunkName: "pages/survey/participants" */))
const _bc29e888 = () => interopDefault(import('../pages/survey/qrcode.vue' /* webpackChunkName: "pages/survey/qrcode" */))
const _45d8f565 = () => interopDefault(import('../pages/survey/questionnaire.vue' /* webpackChunkName: "pages/survey/questionnaire" */))
const _4b20b1d5 = () => interopDefault(import('../pages/zont/orders/index.vue' /* webpackChunkName: "pages/zont/orders/index" */))
const _5b7fb52e = () => interopDefault(import('../pages/catalog/configurator/builder.vue' /* webpackChunkName: "pages/catalog/configurator/builder" */))
const _9c8edd2c = () => interopDefault(import('../pages/catalog/configurator/models/index.vue' /* webpackChunkName: "pages/catalog/configurator/models/index" */))
const _3e0b0457 = () => interopDefault(import('../pages/catalog/configurator/offers/index.vue' /* webpackChunkName: "pages/catalog/configurator/offers/index" */))
const _e6fe2b18 = () => interopDefault(import('../pages/catalog/configurator/online-products/index.vue' /* webpackChunkName: "pages/catalog/configurator/online-products/index" */))
const _fc4c7a14 = () => interopDefault(import('../pages/catalog/configurator/platforms/index.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/index" */))
const _5c37dc66 = () => interopDefault(import('../pages/catalog/configurator/supplied-products.vue' /* webpackChunkName: "pages/catalog/configurator/supplied-products" */))
const _63dfc1be = () => interopDefault(import('../pages/catalog/zont/requests/index.vue' /* webpackChunkName: "pages/catalog/zont/requests/index" */))
const _5b091e4c = () => interopDefault(import('../pages/marketplaces/offers/log.vue' /* webpackChunkName: "pages/marketplaces/offers/log" */))
const _0d8f0d9c = () => interopDefault(import('../pages/pi/declarations/import.vue' /* webpackChunkName: "pages/pi/declarations/import" */))
const _125bf9c4 = () => interopDefault(import('../pages/stock/inventory/audits/index.vue' /* webpackChunkName: "pages/stock/inventory/audits/index" */))
const _044c5c50 = () => interopDefault(import('../pages/stock/serials/check.vue' /* webpackChunkName: "pages/stock/serials/check" */))
const _4fa48f20 = () => interopDefault(import('../pages/stock/serials/new.vue' /* webpackChunkName: "pages/stock/serials/new" */))
const _78d53a03 = () => interopDefault(import('../pages/stock/serials/parse.vue' /* webpackChunkName: "pages/stock/serials/parse" */))
const _19a8f6ba = () => interopDefault(import('../pages/catalog/configurator/online-products/provider-import.vue' /* webpackChunkName: "pages/catalog/configurator/online-products/provider-import" */))
const _786c307f = () => interopDefault(import('../pages/catalog/configurator/offers/_id.vue' /* webpackChunkName: "pages/catalog/configurator/offers/_id" */))
const _0adde8a2 = () => interopDefault(import('../pages/catalog/configurator/online-products/_product_id/index.vue' /* webpackChunkName: "pages/catalog/configurator/online-products/_product_id/index" */))
const _2d2bf292 = () => interopDefault(import('../pages/catalog/configurator/platforms/_platform_id/index.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/_platform_id/index" */))
const _6961c8b4 = () => interopDefault(import('../pages/catalog/zont/requests/_id.vue' /* webpackChunkName: "pages/catalog/zont/requests/_id" */))
const _3ecf41ee = () => interopDefault(import('../pages/stock/inventory/audits/_id/index.vue' /* webpackChunkName: "pages/stock/inventory/audits/_id/index" */))
const _799365bd = () => interopDefault(import('../pages/catalog/configurator/online-products/_product_id/edit.vue' /* webpackChunkName: "pages/catalog/configurator/online-products/_product_id/edit" */))
const _cec91de0 = () => interopDefault(import('../pages/catalog/configurator/platforms/_platform_id/import.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/_platform_id/import" */))
const _c6e39b68 = () => interopDefault(import('../pages/catalog/configurator/platforms/_platform_id/pricelist.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/_platform_id/pricelist" */))
const _2bb3edd2 = () => interopDefault(import('../pages/catalog/configurator/platforms/_platform_id/products/index.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/_platform_id/products/index" */))
const _dc91768e = () => interopDefault(import('../pages/stock/inventory/audits/_id/details.vue' /* webpackChunkName: "pages/stock/inventory/audits/_id/details" */))
const _152da125 = () => interopDefault(import('../pages/catalog/configurator/platforms/_platform_id/products/generate.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/_platform_id/products/generate" */))
const _7f2fe96b = () => interopDefault(import('../pages/catalog/configurator/platforms/_platform_id/products/tokenize.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/_platform_id/products/tokenize" */))
const _eecb3b10 = () => interopDefault(import('../pages/catalog/configurator/platforms/_platform_id/products/tokenize_from_price.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/_platform_id/products/tokenize_from_price" */))
const _0ccd9d22 = () => interopDefault(import('../pages/catalog/configurator/platforms/_platform_id/bases/_base_id.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/_platform_id/bases/_base_id" */))
const _d5b3d7c6 = () => interopDefault(import('../pages/catalog/configurator/platforms/_platform_id/products/_pn.vue' /* webpackChunkName: "pages/catalog/configurator/platforms/_platform_id/products/_pn" */))
const _757160bd = () => interopDefault(import('../pages/catalog/orders/_id.vue' /* webpackChunkName: "pages/catalog/orders/_id" */))
const _00a7838f = () => interopDefault(import('../pages/catalog/product/_code.vue' /* webpackChunkName: "pages/catalog/product/_code" */))
const _1e151dd7 = () => interopDefault(import('../pages/enrollment/orders/_id/index.vue' /* webpackChunkName: "pages/enrollment/orders/_id/index" */))
const _0c532f47 = () => interopDefault(import('../pages/enrollment/transactions/_id/index.vue' /* webpackChunkName: "pages/enrollment/transactions/_id/index" */))
const _2deb44cb = () => interopDefault(import('../pages/marketplace/orders/_id.vue' /* webpackChunkName: "pages/marketplace/orders/_id" */))
const _506a55d3 = () => interopDefault(import('../pages/marketplaces/dispatches/_id.vue' /* webpackChunkName: "pages/marketplaces/dispatches/_id" */))
const _809c75e0 = () => interopDefault(import('../pages/marketplaces/orders/_id.vue' /* webpackChunkName: "pages/marketplaces/orders/_id" */))
const _1d8b511a = () => interopDefault(import('../pages/marketplaces/reports/_id.vue' /* webpackChunkName: "pages/marketplaces/reports/_id" */))
const _0c004f18 = () => interopDefault(import('../pages/pi/bank_accounts/_id/index.vue' /* webpackChunkName: "pages/pi/bank_accounts/_id/index" */))
const _798d13d4 = () => interopDefault(import('../pages/pi/declarations/_id/index.vue' /* webpackChunkName: "pages/pi/declarations/_id/index" */))
const _b17b60fa = () => interopDefault(import('../pages/pi/invoices/_id/index.vue' /* webpackChunkName: "pages/pi/invoices/_id/index" */))
const _0a097fb5 = () => interopDefault(import('../pages/pi/packing/_id.vue' /* webpackChunkName: "pages/pi/packing/_id" */))
const _ffca4d04 = () => interopDefault(import('../pages/pi/products/_id.vue' /* webpackChunkName: "pages/pi/products/_id" */))
const _155bd065 = () => interopDefault(import('../pages/pi/shipments/_id.vue' /* webpackChunkName: "pages/pi/shipments/_id" */))
const _3fb7a081 = () => interopDefault(import('../pages/sales/customizable/_id.vue' /* webpackChunkName: "pages/sales/customizable/_id" */))
const _53a10ad0 = () => interopDefault(import('../pages/sales/reported/_id.vue' /* webpackChunkName: "pages/sales/reported/_id" */))
const _0577d030 = () => interopDefault(import('../pages/stock/packages/_id.vue' /* webpackChunkName: "pages/stock/packages/_id" */))
const _c77ba542 = () => interopDefault(import('../pages/stock/products/_id.vue' /* webpackChunkName: "pages/stock/products/_id" */))
const _771038ec = () => interopDefault(import('../pages/stock/serials/_id.vue' /* webpackChunkName: "pages/stock/serials/_id" */))
const _6d29b506 = () => interopDefault(import('../pages/zont/orders/_id.vue' /* webpackChunkName: "pages/zont/orders/_id" */))
const _a89b21b6 = () => interopDefault(import('../pages/enrollment/transactions/_id/edit.vue' /* webpackChunkName: "pages/enrollment/transactions/_id/edit" */))
const _729f5df6 = () => interopDefault(import('../pages/pi/bank_accounts/_id/new_transaction.vue' /* webpackChunkName: "pages/pi/bank_accounts/_id/new_transaction" */))
const _21506860 = () => interopDefault(import('../pages/pi/declarations/_id/duties.vue' /* webpackChunkName: "pages/pi/declarations/_id/duties" */))
const _a45709f4 = () => interopDefault(import('../pages/pi/invoices/_id/new-shipment.vue' /* webpackChunkName: "pages/pi/invoices/_id/new-shipment" */))
const _188b9a2a = () => interopDefault(import('../pages/pi/calc/_calc_type/_id.vue' /* webpackChunkName: "pages/pi/calc/_calc_type/_id" */))
const _99472b4e = () => interopDefault(import('../pages/customers/_id.vue' /* webpackChunkName: "pages/customers/_id" */))
const _7a4934ea = () => interopDefault(import('../pages/deliveries/_docId.vue' /* webpackChunkName: "pages/deliveries/_docId" */))
const _fa1e5be4 = () => interopDefault(import('../pages/documentation/_slug.vue' /* webpackChunkName: "pages/documentation/_slug" */))
const _1cfe07c2 = () => interopDefault(import('../pages/marketplaces/_market_id/index.vue' /* webpackChunkName: "pages/marketplaces/_market_id/index" */))
const _6a0995e3 = () => interopDefault(import('../pages/ocs/_id.vue' /* webpackChunkName: "pages/ocs/_id" */))
const _3b7b3e62 = () => interopDefault(import('../pages/transits/_id.vue' /* webpackChunkName: "pages/transits/_id" */))
const _22d70cee = () => interopDefault(import('@/pages/catalog/search/index.vue' /* webpackChunkName: "" */))
const _37e3003e = () => interopDefault(import('@/pages/catalog/product/_code.vue' /* webpackChunkName: "" */))
const _1290a10e = () => interopDefault(import('@/pages/catalog/configurator/platforms/_platform_id/products/_pn.vue' /* webpackChunkName: "" */))
const _1600d1ff = () => interopDefault(import('@/pages/marketplaces/orders/_id.vue' /* webpackChunkName: "" */))
const _c509597c = () => interopDefault(import('@/pages/marketplaces/dispatches/_id.vue' /* webpackChunkName: "" */))
const _5bdc825a = () => interopDefault(import('@/pages/marketplaces/dispatches/index.vue' /* webpackChunkName: "" */))
const _921ca306 = () => interopDefault(import('@/pages/documentation/_slug.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/certificates",
    component: _f6be6094,
    name: "certificates"
  }, {
    path: "/copy-excel-table",
    component: _3a3a51d2,
    name: "copy-excel-table"
  }, {
    path: "/costs",
    component: _45346ce2,
    name: "costs"
  }, {
    path: "/customers",
    component: _7dd42fb1,
    name: "customers"
  }, {
    path: "/diadoc",
    component: _4af81c33,
    name: "diadoc"
  }, {
    path: "/login",
    component: _33fd957c,
    name: "login"
  }, {
    path: "/logout",
    component: _27367b81,
    name: "logout"
  }, {
    path: "/survey",
    component: _a2cba9d8,
    name: "survey"
  }, {
    path: "/transits",
    component: _798e257a,
    name: "transits"
  }, {
    path: "/version",
    component: _f79db21e,
    name: "version"
  }, {
    path: "/admin/dashboard",
    component: _15259226,
    name: "admin-dashboard"
  }, {
    path: "/admin/hello",
    component: _ff7fc2aa,
    name: "admin-hello"
  }, {
    path: "/admin/logs",
    component: _2000f066,
    name: "admin-logs"
  }, {
    path: "/admin/our-stock",
    component: _cf1fd5a4,
    name: "admin-our-stock"
  }, {
    path: "/admin/sidekiq",
    component: _81e11a96,
    name: "admin-sidekiq"
  }, {
    path: "/calc/cpt-ddp",
    component: _299010c7,
    name: "calc-cpt-ddp"
  }, {
    path: "/catalog/lists",
    component: _4cfc0810,
    name: "catalog-lists"
  }, {
    path: "/catalog/orders",
    component: _0e851d15,
    name: "catalog-orders"
  }, {
    path: "/catalog/search",
    component: _bb0c6fd0,
    name: "catalog-search"
  }, {
    path: "/catalog/selection",
    component: _7de618ef,
    name: "catalog-selection"
  }, {
    path: "/certificates/new",
    component: _56211c84,
    name: "certificates-new"
  }, {
    path: "/enrollment/orders",
    component: _672a58ec,
    name: "enrollment-orders"
  }, {
    path: "/enrollment/transactions",
    component: _0aeabb48,
    name: "enrollment-transactions"
  }, {
    path: "/marketplace/offers",
    component: _0110acd2,
    name: "marketplace-offers"
  }, {
    path: "/marketplace/orders",
    component: _dfd394ba,
    name: "marketplace-orders"
  }, {
    path: "/marketplaces/dashboard",
    component: _b5e5d978,
    name: "marketplaces-dashboard"
  }, {
    path: "/marketplaces/dispatches",
    component: _0f1524ab,
    name: "marketplaces-dispatches"
  }, {
    path: "/marketplaces/list",
    component: _5b63551e,
    name: "marketplaces-list"
  }, {
    path: "/marketplaces/orders",
    component: _6b848eb0,
    name: "marketplaces-orders"
  }, {
    path: "/marketplaces/reports",
    component: _17e46a32,
    name: "marketplaces-reports"
  }, {
    path: "/pi/bank_accounts",
    component: _251a52ad,
    name: "pi-bank_accounts"
  }, {
    path: "/pi/calc",
    component: _106e0672,
    name: "pi-calc"
  }, {
    path: "/pi/cost",
    component: _1bfebc3f,
    name: "pi-cost"
  }, {
    path: "/pi/declarations",
    component: _588aa8ab,
    name: "pi-declarations"
  }, {
    path: "/pi/invoices",
    component: _6fab4098,
    name: "pi-invoices"
  }, {
    path: "/pi/packing",
    component: _44dd43e6,
    name: "pi-packing"
  }, {
    path: "/pi/shipments",
    component: _5d9242bd,
    name: "pi-shipments"
  }, {
    path: "/public/upload-images",
    component: _0fa74055,
    name: "public-upload-images"
  }, {
    path: "/purchases/arrivals",
    component: _2607566e,
    name: "purchases-arrivals"
  }, {
    path: "/purchases/extra",
    component: _60e9c2ec,
    name: "purchases-extra"
  }, {
    path: "/reports/delta",
    component: _0fc224c1,
    name: "reports-delta"
  }, {
    path: "/reports/inventory",
    component: _4bf16fe5,
    name: "reports-inventory"
  }, {
    path: "/reports/mac_sales",
    component: _55afd0a5,
    name: "reports-mac_sales"
  }, {
    path: "/reports/report1",
    component: _e66b1074,
    name: "reports-report1"
  }, {
    path: "/sales/current",
    component: _3faec462,
    name: "sales-current"
  }, {
    path: "/sales/customizable",
    component: _603a7bd9,
    name: "sales-customizable"
  }, {
    path: "/sales/real",
    component: _18385890,
    name: "sales-real"
  }, {
    path: "/sales/reported",
    component: _38242c30,
    name: "sales-reported"
  }, {
    path: "/stock/orders",
    component: _1c99bb78,
    name: "stock-orders"
  }, {
    path: "/stock/packages",
    component: _26f29500,
    name: "stock-packages"
  }, {
    path: "/stock/products",
    component: _77556d92,
    name: "stock-products"
  }, {
    path: "/stock/serials",
    component: _9413bebc,
    name: "stock-serials"
  }, {
    path: "/stock/sn_addons",
    component: _c5bb3212,
    name: "stock-sn_addons"
  }, {
    path: "/survey/check-winner",
    component: _3d47d152,
    name: "survey-check-winner"
  }, {
    path: "/survey/participants",
    component: _69939e4e,
    name: "survey-participants"
  }, {
    path: "/survey/qrcode",
    component: _bc29e888,
    name: "survey-qrcode"
  }, {
    path: "/survey/questionnaire",
    component: _45d8f565,
    name: "survey-questionnaire"
  }, {
    path: "/zont/orders",
    component: _4b20b1d5,
    name: "zont-orders"
  }, {
    path: "/catalog/configurator/builder",
    component: _5b7fb52e,
    name: "catalog-configurator-builder"
  }, {
    path: "/catalog/configurator/models",
    component: _9c8edd2c,
    name: "catalog-configurator-models"
  }, {
    path: "/catalog/configurator/offers",
    component: _3e0b0457,
    name: "catalog-configurator-offers"
  }, {
    path: "/catalog/configurator/online-products",
    component: _e6fe2b18,
    name: "catalog-configurator-online-products"
  }, {
    path: "/catalog/configurator/platforms",
    component: _fc4c7a14,
    name: "catalog-configurator-platforms"
  }, {
    path: "/catalog/configurator/supplied-products",
    component: _5c37dc66,
    name: "catalog-configurator-supplied-products"
  }, {
    path: "/catalog/zont/requests",
    component: _63dfc1be,
    name: "catalog-zont-requests"
  }, {
    path: "/marketplaces/offers/log",
    component: _5b091e4c,
    name: "marketplaces-offers-log"
  }, {
    path: "/pi/declarations/import",
    component: _0d8f0d9c,
    name: "pi-declarations-import"
  }, {
    path: "/stock/inventory/audits",
    component: _125bf9c4,
    name: "stock-inventory-audits"
  }, {
    path: "/stock/serials/check",
    component: _044c5c50,
    name: "stock-serials-check"
  }, {
    path: "/stock/serials/new",
    component: _4fa48f20,
    name: "stock-serials-new"
  }, {
    path: "/stock/serials/parse",
    component: _78d53a03,
    name: "stock-serials-parse"
  }, {
    path: "/catalog/configurator/online-products/provider-import",
    component: _19a8f6ba,
    name: "catalog-configurator-online-products-provider-import"
  }, {
    path: "/catalog/configurator/offers/:id",
    component: _786c307f,
    name: "catalog-configurator-offers-id"
  }, {
    path: "/catalog/configurator/online-products/:product_id",
    component: _0adde8a2,
    name: "catalog-configurator-online-products-product_id"
  }, {
    path: "/catalog/configurator/platforms/:platform_id",
    component: _2d2bf292,
    name: "catalog-configurator-platforms-platform_id"
  }, {
    path: "/catalog/zont/requests/:id",
    component: _6961c8b4,
    name: "catalog-zont-requests-id"
  }, {
    path: "/stock/inventory/audits/:id",
    component: _3ecf41ee,
    name: "stock-inventory-audits-id"
  }, {
    path: "/catalog/configurator/online-products/:product_id?/edit",
    component: _799365bd,
    name: "catalog-configurator-online-products-product_id-edit"
  }, {
    path: "/catalog/configurator/platforms/:platform_id/import",
    component: _cec91de0,
    name: "catalog-configurator-platforms-platform_id-import"
  }, {
    path: "/catalog/configurator/platforms/:platform_id/pricelist",
    component: _c6e39b68,
    name: "catalog-configurator-platforms-platform_id-pricelist"
  }, {
    path: "/catalog/configurator/platforms/:platform_id/products",
    component: _2bb3edd2,
    name: "catalog-configurator-platforms-platform_id-products"
  }, {
    path: "/stock/inventory/audits/:id/details",
    component: _dc91768e,
    name: "stock-inventory-audits-id-details"
  }, {
    path: "/catalog/configurator/platforms/:platform_id/products/generate",
    component: _152da125,
    name: "catalog-configurator-platforms-platform_id-products-generate"
  }, {
    path: "/catalog/configurator/platforms/:platform_id/products/tokenize",
    component: _7f2fe96b,
    name: "catalog-configurator-platforms-platform_id-products-tokenize"
  }, {
    path: "/catalog/configurator/platforms/:platform_id/products/tokenize_from_price",
    component: _eecb3b10,
    name: "catalog-configurator-platforms-platform_id-products-tokenize_from_price"
  }, {
    path: "/catalog/configurator/platforms/:platform_id/bases/:base_id?",
    component: _0ccd9d22,
    name: "catalog-configurator-platforms-platform_id-bases-base_id"
  }, {
    path: "/catalog/configurator/platforms/:platform_id/products/:pn",
    component: _d5b3d7c6,
    name: "catalog-configurator-platforms-platform_id-products-pn"
  }, {
    path: "/catalog/orders/:id",
    component: _757160bd,
    name: "catalog-orders-id"
  }, {
    path: "/catalog/product/:code?",
    component: _00a7838f,
    name: "catalog-product-code"
  }, {
    path: "/enrollment/orders/:id",
    component: _1e151dd7,
    name: "enrollment-orders-id"
  }, {
    path: "/enrollment/transactions/:id",
    component: _0c532f47,
    name: "enrollment-transactions-id"
  }, {
    path: "/marketplace/orders/:id",
    component: _2deb44cb,
    name: "marketplace-orders-id"
  }, {
    path: "/marketplaces/dispatches/:id",
    component: _506a55d3,
    name: "marketplaces-dispatches-id"
  }, {
    path: "/marketplaces/orders/:id",
    component: _809c75e0,
    name: "marketplaces-orders-id"
  }, {
    path: "/marketplaces/reports/:id",
    component: _1d8b511a,
    name: "marketplaces-reports-id"
  }, {
    path: "/pi/bank_accounts/:id",
    component: _0c004f18,
    name: "pi-bank_accounts-id"
  }, {
    path: "/pi/declarations/:id",
    component: _798d13d4,
    name: "pi-declarations-id"
  }, {
    path: "/pi/invoices/:id",
    component: _b17b60fa,
    name: "pi-invoices-id"
  }, {
    path: "/pi/packing/:id",
    component: _0a097fb5,
    name: "pi-packing-id"
  }, {
    path: "/pi/products/:id?",
    component: _ffca4d04,
    name: "pi-products-id"
  }, {
    path: "/pi/shipments/:id",
    component: _155bd065,
    name: "pi-shipments-id"
  }, {
    path: "/sales/customizable/:id",
    component: _3fb7a081,
    name: "sales-customizable-id"
  }, {
    path: "/sales/reported/:id",
    component: _53a10ad0,
    name: "sales-reported-id"
  }, {
    path: "/stock/packages/:id",
    component: _0577d030,
    name: "stock-packages-id"
  }, {
    path: "/stock/products/:id",
    component: _c77ba542,
    name: "stock-products-id"
  }, {
    path: "/stock/serials/:id",
    component: _771038ec,
    name: "stock-serials-id"
  }, {
    path: "/zont/orders/:id",
    component: _6d29b506,
    name: "zont-orders-id"
  }, {
    path: "/enrollment/transactions/:id/edit",
    component: _a89b21b6,
    name: "enrollment-transactions-id-edit"
  }, {
    path: "/pi/bank_accounts/:id/new_transaction",
    component: _729f5df6,
    name: "pi-bank_accounts-id-new_transaction"
  }, {
    path: "/pi/declarations/:id/duties",
    component: _21506860,
    name: "pi-declarations-id-duties"
  }, {
    path: "/pi/invoices/:id/new-shipment",
    component: _a45709f4,
    name: "pi-invoices-id-new-shipment"
  }, {
    path: "/pi/calc/:calc_type/:id?",
    component: _188b9a2a,
    name: "pi-calc-calc_type-id"
  }, {
    path: "/customers/:id",
    component: _99472b4e,
    name: "customers-id"
  }, {
    path: "/deliveries/:docId?",
    component: _7a4934ea,
    name: "deliveries-docId"
  }, {
    path: "/documentation/:slug?",
    component: _fa1e5be4,
    name: "documentation-slug"
  }, {
    path: "/marketplaces/:market_id",
    component: _1cfe07c2,
    name: "marketplaces-market_id"
  }, {
    path: "/ocs/:id?",
    component: _6a0995e3,
    name: "ocs-id"
  }, {
    path: "/transits/:id",
    component: _3b7b3e62,
    name: "transits-id"
  }, {
    path: "/",
    component: _22d70cee,
    name: "Index"
  }, {
    path: "/catalog/product/:code(.*)",
    component: _37e3003e,
    name: "CatalogProduct"
  }, {
    path: "/catalog/configurator/platforms/:platform_id/products/:pn(.*)",
    component: _1290a10e,
    name: "PlatformProduct"
  }, {
    path: "/catalog/platforms/:platformId/search",
    component: _22d70cee,
    name: "searchByPlatform"
  }, {
    path: "/stock/orders/:id",
    component: _1600d1ff,
    name: "StockOnlineOrder"
  }, {
    path: "/stock/dispatches/:id",
    component: _c509597c,
    name: "StockDispatch"
  }, {
    path: "/stock/dispatches/",
    component: _5bdc825a,
    name: "StockDispatches"
  }, {
    path: "/documentation/:slug*",
    component: _921ca306,
    name: "DocumentationSlug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
