export default function ({ $axios, redirect }) {
  $axios.onError((error) => {
    if (!/^\/(composer\/)?(login|public)/.test(window.location.pathname)) {
      if (!error?.response?.status) {
        return;
      }
      if (error.response.status === 401) {
        console.log("NOT AUTHENTICATED, REDIRECTING TO LOGIN...");
        window.location.href = `${$nuxt.$router.options.base}/login`.replace("//", "/");
      }
      if (error.response.status === 403) {
        console.log(error.response.data.message);
        $nuxt.$buefy.notification.open({
          message: error.response.data.message,
          type: "is-danger"
        });
        return Promise.reject(false);
      }
    }
  });
}
