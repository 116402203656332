const bodyBuilder = require("bodybuilder");

export const actions = {
  async esGetAggregation() {
    const request = bodyBuilder();
    request.aggregation("terms", "supplier", "suppliers", {
      size: 10,
      order: { _key: "asc" }
    });
    request.aggregation("terms", "vendor", "vendor", {
      size: 1000,
      order: { _key: "asc" }
    });

    const { aggregations = {} } = await this.$axios.$post(
      `/configurator/suppliers_products/es/search`,
      { search: request.build() }
    );

    return {
      suppliers: aggregations.suppliers?.buckets,
      vendors: aggregations.vendor?.buckets
    };
  },
  async esGetProduct({}, { filters = {}, search = "", paginator = {}, sort = {} }) {
    const request = bodyBuilder();

    prepareFilters(request, filters);
    prepareSearchString(request, search);
    addPagination(request, paginator);

    const { products, total } = await this.$axios.$post(
      `/configurator/suppliers_products/es/search`,
      { search: request.build() }
    );

    return { products, total };
  }
};

function prepareFilters(request, filters) {
  if (filters.vendor) {
    request.filter("term", "vendor", filters.vendor);
  }
  if (filters.supplier) {
    request.filter("term", "supplier", filters.supplier);
  }
  if (filters.pn) {
    request.filter("term", "pn", filters.pn);
  }
}
function prepareSearchString(request, search) {
  if (!search) return;
  request.orQuery("multi_match", "query", search, {
    fields: ["_id^30", "barcode^30", "pn^30", "vendor^20", "prefix^10"]
  });
  request.orQuery("simple_query_string", "query", search, {
    fields: ["full_description"]
  });
  // request.orQuery('match_bool_prefix', 'full_description', search);
  request.queryMinimumShouldMatch(1);
}
function addPagination(request, paginator) {
  if (!paginator.page || !paginator.perPage) return;
  const size = Number(paginator.perPage);
  const from = (Number(paginator.page) - 1) * Number(paginator.perPage);
  request.size(size);
  request.from(from);
}
