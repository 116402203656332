
export default {
  // data() {
  //   return {
  //     paddings: {
  //       left: 15,
  //       top: 15,
  //       right: 15,
  //       bottom: 15,
  //     }
  //   };
  // },
  methods: {
    print() {
      window.print();
    }
  }
};
