export const actions = {
  async esGetFilterOptions({}, { params = {} } = {}) {
    try {
      const must = [];
      const filters = generalFilters({ ...params })?.filters || [];
      const body = {
        size: 0,
        aggs: {
          properties: {
            terms: {
              field: "opts",
              order: { _key: "asc" },
              size: 50
            }
          }
        }
      };

      if (must.length || filters.length) {
        body.query = {
          bool: {}
        };
      }

      if (filters.length) {
        body.query.bool.filter = createfilters(filters);
      }

      const { aggregations } = await this.$axios.$post(`/configurator/search`, body);
      return aggregations.properties.buckets.map((property) => property.key);
    } catch (error) {
      throw error;
    }
  },

  async esGetAggregations(
    {},
    { params = {}, must = generalFilters({ ...params })?.must, aggFilters = [], aggs = [] }
  ) {
    try {
      const filters = generalFilters({ ...params })?.filters || [];
      const body = {
        size: 0
      };

      if (must?.length || filters?.length) {
        body.query = { bool: {} };
      }
      if (must?.length) {
        body.query.bool.must = must;
      }
      if (filters?.length) {
        body.query.bool.filter = createfilters(filters);
      }

      if (aggs?.length || aggFilters?.length) {
        body.aggs = {};
      }
      if (aggs?.length) {
        body.aggs.inActiveFilters = {
          filter: aggFilter(propertiesFilters(aggFilters)),
          aggs: {}
        };

        aggs.forEach((propertyKey) => {
          if (!aggFilters.map((property) => property.key).includes(propertyKey)) {
            body.aggs.inActiveFilters.aggs[propertyKey] = {
              terms: {
                field: `properties.${propertyKey}`,
                size: 100
              }
            };
          }
        });
      }
      if (aggFilters?.length) {
        aggFilters.forEach((property) => {
          body.aggs[property.key] = {
            filter: aggFilter(
              propertiesFilters(aggFilters).filter((_property) => _property.key !== property.key)
            ),
            aggs: {
              property: {
                terms: {
                  field: `properties.${property.key}`,
                  size: 100
                }
              }
            }
          };
        });
      }

      const { aggregations } = await this.$axios.$post(`/configurator/search`, body);
      if (!aggregations || Array.isArray(aggregations.inActiveFilters)) return {};

      return {
        ...Object.keys(aggregations.inActiveFilters).reduce(function (acc, key) {
          if (aggs.indexOf(key) !== -1) {
            acc[key] = aggregations.inActiveFilters[key];
          }
          return acc;
        }, {}),
        ...Object.keys(aggregations).reduce(function (acc, key) {
          if (aggs.indexOf(key) !== -1) {
            acc[key] = aggregations[key].property;
          }
          return acc;
        }, {})
      };
    } catch (error) {
      throw error;
    }
  },

  async esGetProduct({}, params) {
    const body = getSearchBody(params);
    const { hits } = await this.$axios.$post(`/configurator/search`, body);
    return {
      data: hits.hits.map((hit) => hit._source),
      total: hits.total.value
    };
  },

  async esExportProducts({}, params) {
    const body = getSearchBody(params);
    if (params.margin) body.margin = params.margin;
    return this.$axios.$post(`/configurator/search/export_result.xlsx`, body, {
      responseType: "blob"
    });
  },

  async esStrictSearch({}, arrayPnAndIds) {
    try {
      const body = {
        size: 200,
        query: {
          simple_query_string: {
            query: arrayPnAndIds.map((i) => `"${i}"`).join(" "),
            fields: ["pn", "pn.ngram", "_id"]
          }
        }
      };
      const { hits } = await this.$axios.$post(`/configurator/search`, body);
      return hits.hits.map((hit) => hit._source);
    } catch (error) {
      throw error;
    }
  }
};

function getSearchBody({ params = {}, filters = [], paginator = {}, source = [], sort = {} }) {
  const body = {};
  if (source.length) {
    body.source = source;
  }

  if (paginator?.page && paginator?.perPage) {
    body.size = Number(paginator.perPage);
    body.from = (Number(paginator.page) - 1) * Number(paginator.perPage);
  }

  body.sort = [];
  if (sort?.field) {
    body.sort.push({ [sort.field]: { order: sort?.order || "ASC" } });
  }
  body.sort.push("description.raw");

  let { must, filters: fullFilters } = generalFilters(params);
  fullFilters = propertiesFilters(filters).concat(fullFilters || []);

  if (must?.length || fullFilters?.length) {
    body.query = { bool: {} };
  }
  if (must?.length) {
    body.query.bool.must = must;
  }
  if (fullFilters?.length) {
    body.query.bool.filter = createfilters(fullFilters);
  }
  return body;
}

function aggFilter(properties) {
  return {
    bool: {
      filter: createfilters(properties) || []
    }
  };
}

function createfilters(properties) {
  const filter = properties.map((property) => {
    if (property.options?.length > 0) {
      return {
        bool: {
          should: property.options?.map((option) => ({
            bool: {
              should: {
                [property.queryType]: {
                  [property.field]: option
                }
              }
            }
          }))
        }
      };
    } else {
      return {};
    }
  });

  return filter || [];
}

function generalFilters({
  platformId = null,
  bto = false,
  platformIds = [],
  searchString = "",
  vendorId = null,
  fields = []
} = {}) {
  const filters = [];
  const must = [];

  if (platformId) {
    filters.push(
      createFilter({
        propertyKey: "platform_id",
        queryType: "match",
        optionKey: platformId
      })
    );
  }
  if (bto) {
    filters.push(
      createFilter({
        propertyKey: "bto",
        queryType: "term",
        optionKey: bto
      })
    );
  }
  if (!platformId && platformIds.length) {
    filters.push({
      ...createFilter({
        propertyKey: "platform_id",
        queryType: "match_phrase"
      }),
      options: platformIds
    });
  }
  if (searchString) {
    must.push({
      simple_query_string: {
        query: searchString,
        fields: fields?.length ? fields : ["description", "pn.ngram", "_id"]
      }
    });
  }
  if (vendorId) {
    filters.push(
      createFilter({
        propertyKey: "vendor_id",
        queryType: "term",
        optionKey: vendorId
      })
    );
  }

  return { filters, must };
}

function propertiesFilters(properties = []) {
  if (!properties?.length) return [];
  return properties.map((property) => ({
    ...createFilter({
      fieldPrefix: "properties.",
      propertyKey: property.key,
      queryType: "match_phrase"
    }),
    options: property.options
  }));
}

function createFilter({ fieldPrefix, propertyKey, queryType, optionKey }) {
  return {
    field: (fieldPrefix || "") + propertyKey,
    key: propertyKey,
    queryType,
    options: [optionKey]
  };
}
