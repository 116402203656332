export const state = () => ({
  listId: null,
  listName: "",
  listItems: []
});

export const mutations = {
  setSearchList(state, list) {
    state.listId = list.id || null;
    state.listName = list.name || "";
    state.listItems = list.items || [];
  },
  addToSearchList(state, catalogId) {
    state.listItems.push(catalogId);
  },
  removeFromSearchList(state, catalogId) {
    state.listItems = state.listItems.filter((id) => id !== catalogId);
  },
  clearList(state) {
    state.listId = null;
    state.listName = "";
    state.listItems = [];
  }
};

export const actions = {
  syncListAndLocal({ state }) {
    if (state.listItems?.length) {
      const list = {
        id: state.listId,
        name: state.listName,
        items: state.listItems
      };
      window.localStorage.setItem("searchList", JSON.stringify(list));
    } else {
      window.localStorage.removeItem("searchList");
    }
  },
  async loadList({ commit }) {
    try {
      let currentList = window.localStorage.getItem("searchList");
      if (!!currentList) {
        currentList = JSON.parse(currentList);
      } else if (true) {
        //убрать
        currentList = {}; //убрать
      } else {
        currentList = await this.$axios.$get(`/composer/getList/${state.listId}`);
      }

      commit("setSearchList", currentList || {});
    } catch (error) {
      throw error;
    }
  },
  async saveList({ commit }, listName) {
    // TODO: Добавить сохранение на сервер
    const list = {};
    commit("setSearchList", list);
  },
  async saveListAsNew({}, listName) {
    // TODO: Добавить сохранение на сервер
    const list = {};
    commit("setSearchList", list);
  },
  rollbackList({ commit, dispatch }) {
    commit("clearList");
    dispatch("syncListAndLocal");
  }
};
