export const AddNote = () => import('../../components/AddNote.vue' /* webpackChunkName: "components/add-note" */).then(c => wrapFunctional(c.default || c))
export const AttachmentUploader = () => import('../../components/AttachmentUploader.vue' /* webpackChunkName: "components/attachment-uploader" */).then(c => wrapFunctional(c.default || c))
export const Chart = () => import('../../components/Chart.vue' /* webpackChunkName: "components/chart" */).then(c => wrapFunctional(c.default || c))
export const CopiedText = () => import('../../components/CopiedText.vue' /* webpackChunkName: "components/copied-text" */).then(c => wrapFunctional(c.default || c))
export const CopyText = () => import('../../components/CopyText.vue' /* webpackChunkName: "components/copy-text" */).then(c => wrapFunctional(c.default || c))
export const DiffByPercent = () => import('../../components/DiffByPercent.vue' /* webpackChunkName: "components/diff-by-percent" */).then(c => wrapFunctional(c.default || c))
export const DocumentationNavigation = () => import('../../components/DocumentationNavigation.vue' /* webpackChunkName: "components/documentation-navigation" */).then(c => wrapFunctional(c.default || c))
export const DragAndDrop = () => import('../../components/DragAndDrop.vue' /* webpackChunkName: "components/drag-and-drop" */).then(c => wrapFunctional(c.default || c))
export const EventLog = () => import('../../components/EventLog.vue' /* webpackChunkName: "components/event-log" */).then(c => wrapFunctional(c.default || c))
export const EventLogs = () => import('../../components/EventLogs.vue' /* webpackChunkName: "components/event-logs" */).then(c => wrapFunctional(c.default || c))
export const FiltersBlock = () => import('../../components/FiltersBlock.vue' /* webpackChunkName: "components/filters-block" */).then(c => wrapFunctional(c.default || c))
export const FormTemplate = () => import('../../components/FormTemplate.vue' /* webpackChunkName: "components/form-template" */).then(c => wrapFunctional(c.default || c))
export const GenPDFServicePage = () => import('../../components/GenPDFServicePage.vue' /* webpackChunkName: "components/gen-p-d-f-service-page" */).then(c => wrapFunctional(c.default || c))
export const MainSideBar = () => import('../../components/MainSideBar.vue' /* webpackChunkName: "components/main-side-bar" */).then(c => wrapFunctional(c.default || c))
export const ModalCreateСharacteristic = () => import('../../components/ModalCreateСharacteristic.vue' /* webpackChunkName: "components/modal-createсharacteristic" */).then(c => wrapFunctional(c.default || c))
export const ModalQR = () => import('../../components/ModalQR.vue' /* webpackChunkName: "components/modal-q-r" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const Notes = () => import('../../components/Notes.vue' /* webpackChunkName: "components/notes" */).then(c => wrapFunctional(c.default || c))
export const PieChart = () => import('../../components/PieChart.vue' /* webpackChunkName: "components/pie-chart" */).then(c => wrapFunctional(c.default || c))
export const PnOnStock = () => import('../../components/PnOnStock.vue' /* webpackChunkName: "components/pn-on-stock" */).then(c => wrapFunctional(c.default || c))
export const SelectOrCreateField = () => import('../../components/SelectOrCreateField.vue' /* webpackChunkName: "components/select-or-create-field" */).then(c => wrapFunctional(c.default || c))
export const TreeSelect = () => import('../../components/TreeSelect.vue' /* webpackChunkName: "components/tree-select" */).then(c => wrapFunctional(c.default || c))
export const TreeSelectItem = () => import('../../components/TreeSelectItem.vue' /* webpackChunkName: "components/tree-select-item" */).then(c => wrapFunctional(c.default || c))
export const FiltersBlockOptionList = () => import('../../components/FiltersBlock/OptionList.vue' /* webpackChunkName: "components/filters-block-option-list" */).then(c => wrapFunctional(c.default || c))
export const SerialsDetailRow = () => import('../../components/Serials/DetailRow.vue' /* webpackChunkName: "components/serials-detail-row" */).then(c => wrapFunctional(c.default || c))
export const SerialsFormDocumentAuto = () => import('../../components/Serials/FormDocumentAuto.vue' /* webpackChunkName: "components/serials-form-document-auto" */).then(c => wrapFunctional(c.default || c))
export const SerialsFormDocumentEdit = () => import('../../components/Serials/FormDocumentEdit.vue' /* webpackChunkName: "components/serials-form-document-edit" */).then(c => wrapFunctional(c.default || c))
export const SerialsFormDocumentManual = () => import('../../components/Serials/FormDocumentManual.vue' /* webpackChunkName: "components/serials-form-document-manual" */).then(c => wrapFunctional(c.default || c))
export const SerialsSNDisplay = () => import('../../components/Serials/SNDisplay.vue' /* webpackChunkName: "components/serials-s-n-display" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorOption = () => import('../../components/configurator/option.vue' /* webpackChunkName: "components/configurator-option" */).then(c => wrapFunctional(c.default || c))
export const EnrollmentABMStatusTag = () => import('../../components/enrollment/ABMStatusTag.vue' /* webpackChunkName: "components/enrollment-a-b-m-status-tag" */).then(c => wrapFunctional(c.default || c))
export const EnrollmentDeliveryCreateForm = () => import('../../components/enrollment/DeliveryCreateForm.vue' /* webpackChunkName: "components/enrollment-delivery-create-form" */).then(c => wrapFunctional(c.default || c))
export const EnrollmentOrderCreateForm = () => import('../../components/enrollment/OrderCreateForm.vue' /* webpackChunkName: "components/enrollment-order-create-form" */).then(c => wrapFunctional(c.default || c))
export const EventLogBaseEvent = () => import('../../components/eventLog/BaseEvent.vue' /* webpackChunkName: "components/event-log-base-event" */).then(c => wrapFunctional(c.default || c))
export const EventLogDefaultEvent = () => import('../../components/eventLog/DefaultEvent.vue' /* webpackChunkName: "components/event-log-default-event" */).then(c => wrapFunctional(c.default || c))
export const EventBody = () => import('../../components/eventLog/EventBody.vue' /* webpackChunkName: "components/event-body" */).then(c => wrapFunctional(c.default || c))
export const EventLogNoteEvent = () => import('../../components/eventLog/NoteEvent.vue' /* webpackChunkName: "components/event-log-note-event" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceCancel = () => import('../../components/marketplace/Cancel.vue' /* webpackChunkName: "components/marketplace-cancel" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceConfirm = () => import('../../components/marketplace/Confirm.vue' /* webpackChunkName: "components/marketplace-confirm" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceDeliver = () => import('../../components/marketplace/Deliver.vue' /* webpackChunkName: "components/marketplace-deliver" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceDispatch = () => import('../../components/marketplace/Dispatch.vue' /* webpackChunkName: "components/marketplace-dispatch" */).then(c => wrapFunctional(c.default || c))
export const MarketplacePrepare = () => import('../../components/marketplace/Prepare.vue' /* webpackChunkName: "components/marketplace-prepare" */).then(c => wrapFunctional(c.default || c))
export const MarketplaceRollback = () => import('../../components/marketplace/Rollback.vue' /* webpackChunkName: "components/marketplace-rollback" */).then(c => wrapFunctional(c.default || c))
export const MarketplacesDispatchForm = () => import('../../components/marketplaces/DispatchForm.vue' /* webpackChunkName: "components/marketplaces-dispatch-form" */).then(c => wrapFunctional(c.default || c))
export const MpCustomerCard = () => import('../../components/mp/CustomerCard.vue' /* webpackChunkName: "components/mp-customer-card" */).then(c => wrapFunctional(c.default || c))
export const MpOfferCard = () => import('../../components/mp/OfferCard.vue' /* webpackChunkName: "components/mp-offer-card" */).then(c => wrapFunctional(c.default || c))
export const MpOrderStatus = () => import('../../components/mp/OrderStatus.vue' /* webpackChunkName: "components/mp-order-status" */).then(c => wrapFunctional(c.default || c))
export const PiDeclarationDuties = () => import('../../components/pi/DeclarationDuties.vue' /* webpackChunkName: "components/pi-declaration-duties" */).then(c => wrapFunctional(c.default || c))
export const PiDeclarationItemsDuties = () => import('../../components/pi/DeclarationItemsDuties.vue' /* webpackChunkName: "components/pi-declaration-items-duties" */).then(c => wrapFunctional(c.default || c))
export const SurveyAnswerButton = () => import('../../components/survey/AnswerButton.vue' /* webpackChunkName: "components/survey-answer-button" */).then(c => wrapFunctional(c.default || c))
export const SurveyCustomButton = () => import('../../components/survey/CustomButton.vue' /* webpackChunkName: "components/survey-custom-button" */).then(c => wrapFunctional(c.default || c))
export const SurveyCustomCheckbox = () => import('../../components/survey/CustomCheckbox.vue' /* webpackChunkName: "components/survey-custom-checkbox" */).then(c => wrapFunctional(c.default || c))
export const SurveyCustomInput = () => import('../../components/survey/CustomInput.vue' /* webpackChunkName: "components/survey-custom-input" */).then(c => wrapFunctional(c.default || c))
export const SurveyQuestionnaireSteps = () => import('../../components/survey/QuestionnaireSteps.vue' /* webpackChunkName: "components/survey-questionnaire-steps" */).then(c => wrapFunctional(c.default || c))
export const ZontModalNoteForm = () => import('../../components/zont/ModalNoteForm.vue' /* webpackChunkName: "components/zont-modal-note-form" */).then(c => wrapFunctional(c.default || c))
export const ZontRequestCard = () => import('../../components/zont/RequestCard.vue' /* webpackChunkName: "components/zont-request-card" */).then(c => wrapFunctional(c.default || c))
export const ZontRequestDetail = () => import('../../components/zont/RequestDetail.vue' /* webpackChunkName: "components/zont-request-detail" */).then(c => wrapFunctional(c.default || c))
export const ZontRequestInfo = () => import('../../components/zont/RequestInfo.vue' /* webpackChunkName: "components/zont-request-info" */).then(c => wrapFunctional(c.default || c))
export const ZontRequestNotes = () => import('../../components/zont/RequestNotes.vue' /* webpackChunkName: "components/zont-request-notes" */).then(c => wrapFunctional(c.default || c))
export const ZontRequestNotesSimple = () => import('../../components/zont/RequestNotesSimple.vue' /* webpackChunkName: "components/zont-request-notes-simple" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorOnlineProductsBarcodeInput = () => import('../../components/configurator/onlineProducts/BarcodeInput.vue' /* webpackChunkName: "components/configurator-online-products-barcode-input" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorOnlineProductsFormCreateProductBySource = () => import('../../components/configurator/onlineProducts/FormCreateProductBySource.vue' /* webpackChunkName: "components/configurator-online-products-form-create-product-by-source" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorOnlineProductsGetProductFromSource = () => import('../../components/configurator/onlineProducts/GetProductFromSource.vue' /* webpackChunkName: "components/configurator-online-products-get-product-from-source" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorOnlineProductsProductFromESSuppliers = () => import('../../components/configurator/onlineProducts/ProductFromESSuppliers.vue' /* webpackChunkName: "components/configurator-online-products-product-from-e-s-suppliers" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorOnlineProductsProductFromProviderCard = () => import('../../components/configurator/onlineProducts/ProductFromProviderCard.vue' /* webpackChunkName: "components/configurator-online-products-product-from-provider-card" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorPlatformsCardBases = () => import('../../components/configurator/platforms/CardBases.vue' /* webpackChunkName: "components/configurator-platforms-card-bases" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorPlatformsCardOption = () => import('../../components/configurator/platforms/CardOption.vue' /* webpackChunkName: "components/configurator-platforms-card-option" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorPlatformsCardProperty = () => import('../../components/configurator/platforms/CardProperty.vue' /* webpackChunkName: "components/configurator-platforms-card-property" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorPlatformsFormCreateOption = () => import('../../components/configurator/platforms/FormCreateOption.vue' /* webpackChunkName: "components/configurator-platforms-form-create-option" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorPlatformsFormCreateСharacteristic = () => import('../../components/configurator/platforms/FormCreateСharacteristic.vue' /* webpackChunkName: "components/configurator-platforms-form-createсharacteristic" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorSearchProductDetails = () => import('../../components/configurator/search/ProductDetails.vue' /* webpackChunkName: "components/configurator-search-product-details" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorSuppliedProductsAdditionalProductData = () => import('../../components/configurator/suppliedProducts/AdditionalProductData.vue' /* webpackChunkName: "components/configurator-supplied-products-additional-product-data" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorSuppliedProductsOffersDataView = () => import('../../components/configurator/suppliedProducts/OffersDataView.vue' /* webpackChunkName: "components/configurator-supplied-products-offers-data-view" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorSuppliedProductsDihouseDataView = () => import('../../components/configurator/suppliedProducts/dihouseDataView.vue' /* webpackChunkName: "components/configurator-supplied-products-dihouse-data-view" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorSuppliedProductsMarvelDataView = () => import('../../components/configurator/suppliedProducts/marvelDataView.vue' /* webpackChunkName: "components/configurator-supplied-products-marvel-data-view" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorSuppliedProductsMerlionDataView = () => import('../../components/configurator/suppliedProducts/merlionDataView.vue' /* webpackChunkName: "components/configurator-supplied-products-merlion-data-view" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorSuppliedProductsOcsDataView = () => import('../../components/configurator/suppliedProducts/ocsDataView.vue' /* webpackChunkName: "components/configurator-supplied-products-ocs-data-view" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorSuppliedProductsOtherDataView = () => import('../../components/configurator/suppliedProducts/otherDataView.vue' /* webpackChunkName: "components/configurator-supplied-products-other-data-view" */).then(c => wrapFunctional(c.default || c))
export const ConfiguratorSuppliedProductsTreolanDataView = () => import('../../components/configurator/suppliedProducts/treolanDataView.vue' /* webpackChunkName: "components/configurator-supplied-products-treolan-data-view" */).then(c => wrapFunctional(c.default || c))
export const EventLogMpDispatchCompleteEvent = () => import('../../components/eventLog/mpDispatch/CompleteEvent.vue' /* webpackChunkName: "components/event-log-mp-dispatch-complete-event" */).then(c => wrapFunctional(c.default || c))
export const EventLogMpOrderCancelEvent = () => import('../../components/eventLog/mpOrder/CancelEvent.vue' /* webpackChunkName: "components/event-log-mp-order-cancel-event" */).then(c => wrapFunctional(c.default || c))
export const EventLogMpOrderCreate1cDocumentsEvent = () => import('../../components/eventLog/mpOrder/Create1cDocumentsEvent.vue' /* webpackChunkName: "components/event-log-mp-order-create1c-documents-event" */).then(c => wrapFunctional(c.default || c))
export const EventLogMpOrderCreateReservationEvent = () => import('../../components/eventLog/mpOrder/CreateReservationEvent.vue' /* webpackChunkName: "components/event-log-mp-order-create-reservation-event" */).then(c => wrapFunctional(c.default || c))
export const EventLogMpOrderDeleteReservationEvent = () => import('../../components/eventLog/mpOrder/DeleteReservationEvent.vue' /* webpackChunkName: "components/event-log-mp-order-delete-reservation-event" */).then(c => wrapFunctional(c.default || c))
export const EventLogMpOrderDeliverEvent = () => import('../../components/eventLog/mpOrder/DeliverEvent.vue' /* webpackChunkName: "components/event-log-mp-order-deliver-event" */).then(c => wrapFunctional(c.default || c))
export const EventLogMpOrderGetbackEvent = () => import('../../components/eventLog/mpOrder/GetbackEvent.vue' /* webpackChunkName: "components/event-log-mp-order-getback-event" */).then(c => wrapFunctional(c.default || c))
export const EventLogMpOrderPackEvent = () => import('../../components/eventLog/mpOrder/PackEvent.vue' /* webpackChunkName: "components/event-log-mp-order-pack-event" */).then(c => wrapFunctional(c.default || c))
export const EventLogMpOrderShipEvent = () => import('../../components/eventLog/mpOrder/ShipEvent.vue' /* webpackChunkName: "components/event-log-mp-order-ship-event" */).then(c => wrapFunctional(c.default || c))
export const MarketplacesOrdersBaseAction = () => import('../../components/marketplaces/orders/BaseAction.vue' /* webpackChunkName: "components/marketplaces-orders-base-action" */).then(c => wrapFunctional(c.default || c))
export const MarketplacesOrdersCancel = () => import('../../components/marketplaces/orders/Cancel.vue' /* webpackChunkName: "components/marketplaces-orders-cancel" */).then(c => wrapFunctional(c.default || c))
export const MarketplacesOrdersDeliver = () => import('../../components/marketplaces/orders/Deliver.vue' /* webpackChunkName: "components/marketplaces-orders-deliver" */).then(c => wrapFunctional(c.default || c))
export const MarketplacesOrdersGetback = () => import('../../components/marketplaces/orders/Getback.vue' /* webpackChunkName: "components/marketplaces-orders-getback" */).then(c => wrapFunctional(c.default || c))
export const MarketplacesOrdersNote = () => import('../../components/marketplaces/orders/Note.vue' /* webpackChunkName: "components/marketplaces-orders-note" */).then(c => wrapFunctional(c.default || c))
export const MarketplacesOrdersPack = () => import('../../components/marketplaces/orders/Pack.vue' /* webpackChunkName: "components/marketplaces-orders-pack" */).then(c => wrapFunctional(c.default || c))
export const MarketplacesOrdersShip = () => import('../../components/marketplaces/orders/Ship.vue' /* webpackChunkName: "components/marketplaces-orders-ship" */).then(c => wrapFunctional(c.default || c))
export const PiCalcCptToDdp = () => import('../../components/pi/calc/CptToDdp.vue' /* webpackChunkName: "components/pi-calc-cpt-to-ddp" */).then(c => wrapFunctional(c.default || c))
export const PiCalcFobToCpt = () => import('../../components/pi/calc/FobToCpt.vue' /* webpackChunkName: "components/pi-calc-fob-to-cpt" */).then(c => wrapFunctional(c.default || c))
export const PiCalcFormField = () => import('../../components/pi/calc/FormField.vue' /* webpackChunkName: "components/pi-calc-form-field" */).then(c => wrapFunctional(c.default || c))
export const PiCalcFormFieldset = () => import('../../components/pi/calc/FormFieldset.vue' /* webpackChunkName: "components/pi-calc-form-fieldset" */).then(c => wrapFunctional(c.default || c))
export const PiCalcModal = () => import('../../components/pi/calc/Modal.vue' /* webpackChunkName: "components/pi-calc-modal" */).then(c => wrapFunctional(c.default || c))
export const PiCalcProduct = () => import('../../components/pi/calc/Product.vue' /* webpackChunkName: "components/pi-calc-product" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
