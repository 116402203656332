export default function ({ redirect, route, store, error }) {
  if (/^\/(composer\/)?public/.test(route.path)) {
    return;
  }
  if (!store.state.auth.user && !/^\/login/.test(route.path)) {
    sessionStorage.setItem("beforeLogin", route.fullPath);
    return redirect("/login");
  } else if (store.state.auth.user && /^\/login/.test(route.path)) {
    return redirect("/");
  } else if (store.state.auth.user && !/^\/login/.test(route.path)) {
    if ("beforeLogin" in sessionStorage) {
      const beforeLogin = sessionStorage.getItem("beforeLogin");
      sessionStorage.removeItem("beforeLogin");
      return redirect(beforeLogin);
    }
    if (/^\/admin/.test(route.path) && !store.getters["auth/isAdmin"]) {
      error({ statusCode: 404, message: "This page could not be found" });
    }
  }
  return;
}
