
import Draggable from "vuedraggable";

export default {
  components: { Draggable },
  data() {
    return {
      opened: false,
      products: this.$store.state.buffer.products || []
    };
  },
  computed: {
    isEmpty() {
      return !this.products.length;
    }
  },
  methods: {
    createClone(original) {
      return JSON.parse(JSON.stringify(original));
    },
    removeProduct(product) {
      if (product.pn) {
        this.$store.commit("buffer/removeProductFromBuffer", {
          key: "pn",
          value: product.pn
        });
      } else {
        this.$store.commit("buffer/removeProductFromBuffer", {
          key: "id",
          value: product.id
        });
      }
    }
  }
};
