export const state = () => ({
  user: null
});

export const mutations = {
  setUser(state, user) {
    state.user = user;
    if (user?.email) {
      this.$sentry?.setUser({ email: user.email });
    }
  }
};

export const getters = {
  loggedIn: (state) => !!state.user,
  hasScope: (state) => (scopes) => {
    return scopes?.split(" ").some((scope) => state.user?.roles.includes(scope));
  },
  isAdmin: (state) => ["progressman@gmail.com", "ss.zhukov11@gmail.com"].includes(state.user?.email)
};

export const actions = {
  async getUser({}) {
    try {
      const user = await this.$axios.$get("/profile");
      return user;
    } catch (error) {
      throw error;
    }
  }
};
